import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomSelector } from "store/useStore";

import Page from "components/ui/Page";
import Button from "components/ui/Button";

import HALService from "services/hal";
import BackendService from "services/backend";

import useBasketValues from "hooks/useBasketValues";
import useProducts from "hooks/useProducts";

import { getOrderReceiptHtml } from "utils/helpers/misc";
import { customInsightsEvent } from "utils/helpers/logs";

import { ReactComponent as SuccessIcon } from "assets/img/icons/checkmark.svg";
import { ReactComponent as FailedIcon } from "assets/img/icons/exit.svg";

import { Order as OrderType, OrderState, UserFlowType, OrderError } from "types";

import "./Order.scss";

export default function Order() {
  const { t } = useTranslation();

  const { settings } = useCustomSelector((state) => state.app);
  const { externalPaymentId, userFlowType, cardNumber } = useCustomSelector((state) => state.user);

  const navigate = useNavigate();

  const { total, basketItems } = useBasketValues();
  const { getProducts, getActiveMenus } = useProducts();

  const [progressAnimationContent, setProgressAnimationContent] = useState("...");
  const [orderState, setOrderState] = useState<OrderState>(OrderState.INITIAL);
  const [orderErrorType, setOrderErrorType] = useState<OrderError | null>(null);

  const finishOrderFlow = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    function updateAnimationContent() {
      setProgressAnimationContent((previous) => {
        if (previous.length === 3) {
          return ".";
        }
        return previous + ".";
      });
    }

    let interval = setInterval(updateAnimationContent, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    async function handlePlaceOrder() {
      setOrderState(OrderState.PENDING);
      let orderItems = basketItems;
      let orderTotal = total;

      // Fetch the product to use if this is an employee card
      if (userFlowType === UserFlowType.EMPLOYEE && !!cardNumber) {
        let menuResponse = await BackendService.getMenuByCardNumber(cardNumber);
        if (!menuResponse.isSuccess()) {
          customInsightsEvent("FailedOrder", {
            localTime: new Date().toLocaleString(),
            unitId: settings?.unitId || "Ukendt",
            cardNumber: cardNumber ? `${cardNumber}` : "",
            description: "Request to menu failed",
            errors: menuResponse.error,
          });

          if (menuResponse.error.includes("Account not found")) {
            setOrderState(OrderState.ERROR);
            setOrderErrorType(OrderError.ACCOUNT_NOT_FOUND);
          } else {
            setOrderState(OrderState.ERROR);
            setOrderErrorType(OrderError.EMPTY_PRODUCT_LIST);
          }
          return;
        }

        let activeProducts = getProducts(getActiveMenus(menuResponse.data));
        let orderProduct = activeProducts[0];
        if (!orderProduct) {
          customInsightsEvent("FailedOrder", {
            localTime: new Date().toLocaleString(),
            unitId: settings?.unitId || "Ukendt",
            cardNumber: cardNumber ? `${cardNumber}` : "",
            description: "No active products or menus",
            menus: JSON.stringify(menuResponse.data),
          });
          setOrderState(OrderState.ERROR);
          setOrderErrorType(OrderError.EMPTY_PRODUCT_LIST);
          return;
        }

        orderItems = [{ productId: orderProduct.productId, amount: 1, name: orderProduct.name, price: orderProduct.price }];
        orderTotal = orderProduct.price;
      }

      let order: OrderType = {
        paymentId: externalPaymentId,
        cardNumber: cardNumber ? `${cardNumber}` : null,
        totalPrice: orderTotal,
        orderLine: orderItems.map(({ productId, amount }) => ({ productId, amount })),
      };
      let response = await BackendService.placeOrder(order);
      if (response.isSuccess()) {
        HALService.print(getOrderReceiptHtml({ userFlowType, order, basketItems: orderItems }));
        setOrderState(OrderState.SUCCESS);
      } else {
        customInsightsEvent("FailedOrder", {
          localTime: new Date().toLocaleString(),
          unitId: settings?.unitId || "Ukendt",
          cardNumber: cardNumber ? `${cardNumber}` : "",
          paymentId: externalPaymentId || "",
          description: "Request to order failed",
          products: order.orderLine.map(({ productId, amount }) => `${amount} x ${productId}`).join(", "),
        });
        setOrderState(OrderState.ERROR);
      }
    }
    if (orderState === OrderState.INITIAL) {
      handlePlaceOrder();
    }
  }, [orderState, userFlowType, settings, externalPaymentId, cardNumber, total, basketItems, getProducts, getActiveMenus]);

  useEffect(() => {
    let timeout: any;
    if (orderState === OrderState.SUCCESS) {
      timeout = setTimeout(finishOrderFlow, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [orderState, finishOrderFlow]);

  useEffect(() => {
    let timeout: any;
    if (orderState === OrderState.ERROR) {
      timeout = setTimeout(finishOrderFlow, 15000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [orderState, finishOrderFlow]);

  if (orderState === OrderState.ERROR) {
    return (
      <Page className="order-failed-page">
        <div className="order-failed-content">
          <p className="order-failed-title">
            <FailedIcon /> {t("order:FailedTitle")}
          </p>
          {orderErrorType === OrderError.EMPTY_PRODUCT_LIST && <p>{t("order:EmptyProductList")}</p>}
          {orderErrorType === OrderError.ACCOUNT_NOT_FOUND && <p>{settings?.unitText?.cardNotFoundMessage || t("order:AccountNotFound")}</p>}
          <Button className="retry-button" onClick={() => (userFlowType === UserFlowType.EMPLOYEE ? navigate("/") : navigate("/menu"))}>
            {t("order:GoBack")}
          </Button>
        </div>
      </Page>
    );
  }

  if (orderState === OrderState.SUCCESS) {
    return (
      <Page className="order-success-page">
        <div className="order-success-content">
          <p>
            <SuccessIcon /> {t("order:SuccessTitle")}
          </p>
          <Button className="finish-button" onClick={finishOrderFlow}>
            {t("order:Finish")}
          </Button>
        </div>
      </Page>
    );
  }

  return (
    <Page className="order-progress-page">
      <div className="order-progress-content">
        <p>
          {t("order:ProgressTitle")}
          <span className="progress-animation">{progressAnimationContent}</span>
        </p>
      </div>
    </Page>
  );
}
