import { store } from "store/store";

import HttpErrorResponse from "http/models/HttpErrorResponse";
import HttpSuccessResponse from "http/models/HttpSuccessResponse";

let environment = "";

if (window.location.href.includes("ci.")) {
  environment = "ci.";
} else if (window.location.href.includes("staging.")) {
  environment = "staging.";
}

const getToken = () => {
  return store.getState().app.settings?.authToken.token || "";
};

const getEnvironment = () => {
  return environment;
};

const withPaymentRetryPolicy = async <T>(
  fn: () => Promise<HttpErrorResponse | HttpSuccessResponse<T>>,
  retryCallback: (response: HttpErrorResponse) => void,
  retries = 2
): Promise<HttpErrorResponse | HttpSuccessResponse<T>> => {
  let retryAttempt = 0;

  while (retryAttempt < retries) {
    let response = await fn();
    if (response.isSuccess()) {
      return response;
    } else if (!response.error || !JSON.stringify(response.error).includes("Timeout while waiting for terminal to reply")) {
      // Only retry on timeout errors, otherwise we return the result (e.g. for cancelled transaction)
      return response;
    }

    retryCallback(response);
    retryAttempt++;
  }

  return await fn();
};

export { getToken, getEnvironment, withPaymentRetryPolicy };
